import {Typography, Stack} from "@mui/material";
import ConfirmModal from "components/ConfirmModal";
import DefaultCardStructure from "components/DefaultCardStructure";
import useAlertMessage from "hooks/useAlertMessage";
import pxToRem from "hooks/usePxToRem";
import useToast from "hooks/useToast";
import {useState} from "react";
import {useFormContext, useFieldArray} from "react-hook-form";
import {
  DeleteLogisticPackage,
  PatchProcessById,
} from "services/api_v2/Processes/Processes.service";
import {CustomButton} from "ui";
import {ModalNewPackage} from "views/ProcessDetails/components/ProcessLoad/components";
import {
  DefaultProcessDetails,
  LogisticPackages,
} from "views/ProcessDetails/Process.types";
import ActivityPackageItem from "./components";
import {calcConsideredWeight} from "views/ProcessDetails/components/ProcessLoad/utils/calculateConsideredWeight";
import {useParams} from "react-router";

type Props = {
  refetchProcess: () => Promise<DefaultProcessDetails | undefined>;
};

const ActivityPackages = (props: Props) => {
  const {refetchProcess} = props;
  const {id: processId} = useParams<{id: string}>();

  const loadIndex = 0;
  const {reset, formState, watch, setValue, getValues} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    name: "processPackages",
    keyName: "idForm",
  });

  const handleAppendItem = () => {
    append({
      consideredWeight: 0,
      cubedWeight: 0,
      grossWeight: 0,
      height: 0,
      length: 0,
      cubicMeters: 0,
      netWeight: 0,
      package: 0,
      quantity: 0,
      width: 0,
    });
  };
  const setHouseMeasurements = (shouldDirtyFields = true) => {
    const packages = watch("processPackages");
    const totalGrossWeight =
      packages.reduce((acc, prev) => acc + prev.grossWeight, 0) ?? 0;
    setValue(`processLoads[${loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: shouldDirtyFields,
    });

    const totalMeasurements =
      packages.reduce((acc, prev) => acc + prev.cubicMeters, 0) ?? 0;

    setValue(`processLoads[${loadIndex}].cubicMeters`, totalMeasurements, {
      shouldDirty: shouldDirtyFields,
    });
    const totalQuantities =
      packages.reduce((acc, prev) => acc + prev.quantity, 0) ?? 0;
    setValue(`processLoads[${loadIndex}].quantities`, totalQuantities, {
      shouldDirty: shouldDirtyFields,
    });

    //calcular peso considerado da carga
    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: shouldDirtyFields},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: shouldDirtyFields,
    });
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleCloseModalAfterSave = (payload: Partial<LogisticPackages>) => {
    reset(
      {
        ...formState.defaultValues,
        processPackages: [
          ...formState.defaultValues?.processPackages,
          {...payload},
        ],
      },
      {keepDefaultValues: false},
    );

    setModalIsOpen(false);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  const handleDeleteLogisticPackage = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setConfirmModalIsOpen(true);
  };

  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };

  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      const prevProcessPackages = formState.defaultValues?.processPackages;

      prevProcessPackages.splice(itemToDelete?.index, 1);

      await DeleteLogisticPackage(itemToDelete.itemId);
      remove(itemToDelete.index);

      const newDefault = {
        ...formState.defaultValues,
        processPackages: prevProcessPackages,
      };

      reset(newDefault, {
        keepDirtyValues: false,
        keepValues: true,
      });

      setHouseMeasurements(false);
      const loadValues = getValues("processLoads");
      const newLoads = {
        logisticLoads: [
          {
            id: loadValues[0]?.id,
            quantities: loadValues[0]?.quantities,
            grossWeight: loadValues[0]?.grossWeight,
            cubicMeters: loadValues[0]?.cubicMeters,
            consideredWeight: loadValues[0]?.consideredWeight,
            cubeWeight: loadValues[0]?.cubeWeight,
          },
        ],
      };

      handleClose();
      await PatchProcessById(+processId, newLoads);
      await refetchProcess();
      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  return (
    <>
      {modalIsOpen && (
        <ModalNewPackage
          isOpen={modalIsOpen}
          handleCloseModalAfterSave={handleCloseModalAfterSave}
          handleCloseModal={handleCloseModal}
        />
      )}

      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga/pacote"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}
      <DefaultCardStructure
        title="Itens Da Carga - LCL"
        hasDivider={true}
        isSubcard
        button={
          <CustomButton startIcon="add" onClickFn={handleAppendItem}>
            Incluir item
          </CustomButton>
        }
      >
        <Stack sx={{gap: pxToRem(16)}}>
          {!fields?.length && (
            <Typography>Não existem itens de carga no processo.</Typography>
          )}
          {fields?.map((field, index) => (
            <ActivityPackageItem
              key={index}
              field={field}
              index={index}
              loadIndex={loadIndex}
              handleDeleteLogisticPackage={handleDeleteLogisticPackage}
              setHouseMeasurements={setHouseMeasurements}
            />
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default ActivityPackages;
