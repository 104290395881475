import {Icon, Tooltip, Typography} from "@mui/material";
import {Box, Theme, useTheme} from "@mui/system";
import {ReactNode} from "react";
import {Icon as Iconify} from "@iconify/react";
import {Classifications} from "views/ProcessDetails/Process.types";
import pxToRem from "hooks/usePxToRem";
import {firstLetterToUpperCase} from "util/firstLetterToUpercase";

type Props = {
  classification: Classifications;
};

export const getClientClassificationProperties = (
  classification: Classifications,
  theme: Theme,
): {primary: string; secondary: string; icon: ReactNode} => {
  if (classification === "Diamante") {
    return {
      primary: theme?.palette?.info?.[600],
      secondary: theme?.palette?.info?.[700],
      icon: <Iconify icon="ic:round-diamond" />,
    };
  }

  if (classification === "Ouro") {
    return {
      primary: theme?.palette?.warning?.[600],
      secondary: theme?.palette?.warning?.[700],
      icon: <Iconify icon="game-icons:gold-bar" />,
    };
  }

  if (classification === "Padrão Next") {
    return {
      primary: theme?.palette?.primary?.[600],
      secondary: theme?.palette?.primary?.[700],
      icon: <Iconify icon="solar:play-bold" />,
    };
  }

  if (classification === "Padrão mercado") {
    return {
      primary: theme?.palette?.grey?.[600],
      secondary: theme?.palette?.grey?.[700],
      icon: <Iconify icon="ic:outline-store" />,
    };
  }

  return {
    primary: theme?.palette?.grey?.[600],
    secondary: theme?.palette?.grey?.[700],
    icon: <Iconify icon="ic:outline-store" />,
  };
};

const ClientClassificationChip = (props: Props) => {
  const {classification} = props;
  const theme = useTheme();

  const properties = getClientClassificationProperties(classification, theme);

  return (
    <Tooltip title="Classificação cliente" placement={"right"} arrow>
      <Box
        sx={{
          height: pxToRem(32),
          maxWidth: "fit-content",
          backgroundColor: properties.primary,
          borderRadius: pxToRem(16),
          p: "0.25rem 0.7rem 0.25rem 0.5rem",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          color: "white",
          position: "relative",
        }}
      >
        <Icon
          sx={{
            color: "white",
            zIndex: 2,
          }}
        >
          {properties.icon}
        </Icon>
        <Box
          sx={{
            position: "absolute",
            height: pxToRem(32),
            width: pxToRem(36),
            borderRadius: `${pxToRem(16)} 0 0 ${pxToRem(16)}`,
            backgroundColor: properties.secondary,
            ml: "-0.5rem",
          }}
        />

        <Typography sx={{ml: pxToRem(10), fontSize: pxToRem(14)}}>
          {`${firstLetterToUpperCase(classification)}`}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default ClientClassificationChip;
