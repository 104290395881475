import {createRoot} from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./global/style-guide.scss";
import * as Sentry from "@sentry/react";
import {readLocalStorage} from "hooks/useLocalStorage";

Sentry.init({
  dsn: "https://1c2ef186dc0cca217f7f46491fe796c5@o4508200815099904.ingest.us.sentry.io/4508200825389056",
  integrations: [
    // eslint-disable-next-line no-restricted-globals
    Sentry.reactRouterV5BrowserTracingIntegration({history}),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration(),
  ],
  sendDefaultPii: true,
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "dev",
  beforeSend(event) {
    const user = readLocalStorage("user_info");
    event.user = {
      id: user?.person?.id,
      email: user?.person?.email,
      username: user?.person?.name,
    };
    return event;
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "development" ? false : true,
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

reportWebVitals();
