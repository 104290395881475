import {ClientClassification} from "views/ProcessDetails/Process.types";
import {Modality, Operation} from "./globalEnums";

export const getClientClassification = (
  classifications: ClientClassification[] | null,
  operation: Operation,
  modality: Modality,
): ClientClassification | null => {
  if (!classifications || classifications?.length === 0) {
    return null;
  }
  const classificationBasedOnOperationAndModality = classifications?.find(
    (classification) =>
      classification.modality === modality &&
      classification.operation === operation,
  );

  if (!classificationBasedOnOperationAndModality) return null;

  return classificationBasedOnOperationAndModality;
};
