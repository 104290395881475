import pxToRem from "hooks/usePxToRem";

import {
  MaterialReactTable,
  MRT_ToggleGlobalFilterButton,
  MRT_ShowHideColumnsButton,
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_ColumnSizingState,
  MRT_ColumnOrderState,
  MRT_VisibilityState,
} from "material-react-table";
import {MRT_Localization_PT_BR} from "material-react-table/locales/pt-BR";
import {Box, Icon, Stack, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

import {CustomIconButton} from "ui";
import {CsvTableDownloader} from "components";
import {Dispatch, SetStateAction, useState} from "react";
import {ToolbarButton} from "..";
import useToast from "hooks/useToast";
import {readLocalStorage} from "hooks/useLocalStorage";
import {useBackgroundAndTextColor} from "./utils/useBackgroundAndTextColor";
import {getClientClassificationProperties} from "components/ClientClassificationChip/ClientClassificationChip";
import {getClientClassification} from "util/getClientClassification";

type ButtonData<DataType extends Record<string, unknown>> = {
  iconName: string;
  onClick?: (
    arg: Partial<MRT_Row<DataType>>,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  redirection?: (arg: Partial<MRT_Row<DataType>>) => string;
  tooltipText: string;
  completed?: boolean;
  disabled?: boolean;
};

type ActivityTableProps<DataType extends Record<string, unknown>> = {
  columns: MRT_ColumnDef<DataType>[];
  data: Array<DataType>;
  isLoading: boolean;
  actionButtonsData?: ButtonData<DataType>[];
  renderNewToolbarAction?: JSX.Element;
  enableCsvDownload?: boolean;
  enableGrouping?: boolean;
  inicialGrouping?: string[];
  actionsColumnSize?: number;
  tablePagination?: {pageIndex: number; pageSize: number};
  manualPagination?: boolean;
  onPaginationChange?: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  rowCount?: number;
  rowSelection: MRT_RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<MRT_RowSelectionState>>;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
};

const ActivityTable = <T extends Record<string, unknown>>(
  props: ActivityTableProps<T>,
) => {
  const theme = useTheme();
  const columnsMapping = [
    "mrt-row-select",
    props.columns.map((item) => item?.accessorFn),
  ];

  const [showToast] = useToast();

  const [activityColumnSizing, setActivityColumnSizing] =
    useState<MRT_ColumnSizingState>(
      readLocalStorage("activityColumnSizing") ?? {},
    );
  const [activityColumnOrder, setActivityColumnOrder] =
    useState<MRT_ColumnOrderState>(
      readLocalStorage("activityColumnOrder") ?? columnsMapping,
    );
  const [activityColumnVisibility, setActivityColumnVisibility] =
    useState<MRT_VisibilityState>(
      readLocalStorage("activityColumnVisibility") ?? {
        processNumber: true,
        task: true,
        estimatedStart: true,
        estimatedFinish: true,
        observation: true,
        client: true,
        originAgent: true,
        incoterm: true,
        origin: true,
        destination: true,
        transportCompany: true,
        vessel: true,
        statusBoarding: true,
        estimatedCargoLoad: true,
        cargoLoad: true,
        estimatedCargoUnload: true,
        cargoUnload: true,
        hbl: true,
        mbl: true,
        directMaster: true,
        clientReference: true,
        sale: true,
        shipper: true,
        consignee: true,
        externalReference: true,
        bookingNumber: true,
        voyageFlight: true,
        legalPerson: true,
        deconsolidationNumber: true,
      },
    );

  const handleShowProcess = (row: Partial<MRT_Row<T>>) => {
    const tempRow: any = row;
    const processId = tempRow?.original?.groupActivity?.processes?.id;

    return `/process-details/${processId}`;
  };

  const handleSaveTableView = () => {
    localStorage.setItem(
      "activityColumnSizing",
      JSON.stringify(activityColumnSizing),
    );
    localStorage.setItem(
      "activityColumnOrder",
      JSON.stringify(activityColumnOrder),
    );
    localStorage.setItem(
      "activityColumnVisibility",
      JSON.stringify(activityColumnVisibility),
    );

    showToast(
      "Configurações de visualização da tabela salvas com sucesso!",
      "success",
    );
  };
  const {checkBackgroundAndTextColor} = useBackgroundAndTextColor();

  const getClassificationBorderColor = (row) => {
    const classificationName = getClientClassification(
      row.original?.groupActivity?.processes.client.client
        .clientClassifications,
      row.original?.groupActivity?.processes.operation,
      row.original?.groupActivity?.processes.modality,
    )?.classification?.name;
    if (!classificationName) return null;

    const properties = getClientClassificationProperties(
      classificationName,
      theme,
    );
    return properties;
  };

  return (
    <Box
      sx={{
        height: `calc(100vh - 82px - 100px)`,
        display: "flex",
      }}
    >
      <MaterialReactTable
        columns={props.columns}
        data={props.data}
        initialState={{
          density: "compact",
          pagination: props.tablePagination,
          grouping: props.inicialGrouping,
          expanded: true,
          columnPinning: {
            left: ["mrt-row-actions"],
          },
        }}
        enableRowSelection={!!props?.data[0]?.isCompletedInBatch}
        getRowId={(originalRow) => originalRow?.id?.toString() ?? ""}
        onRowSelectionChange={props.setRowSelection}
        rowCount={props.rowCount}
        state={{
          isLoading: props.isLoading,
          pagination: props.tablePagination,
          rowSelection: props.rowSelection,
          columnSizing: activityColumnSizing,
          columnOrder: activityColumnOrder,
          columnVisibility: activityColumnVisibility,
        }}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        manualPagination={props.manualPagination}
        onPaginationChange={props.onPaginationChange}
        enableColumnResizing
        layoutMode="grid"
        enableRowActions
        enableStickyFooter
        enableColumnFilters={false}
        enableStickyHeader
        enableGrouping={props.enableGrouping}
        enableHiding
        enableColumnDragging
        enableColumnOrdering
        positionActionsColumn="first"
        onGlobalFilterChange={props.setGlobalFilter}
        onColumnSizingChange={setActivityColumnSizing}
        onColumnOrderChange={setActivityColumnOrder}
        onColumnVisibilityChange={setActivityColumnVisibility}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            size: props.actionsColumnSize ?? 100,
            muiTableHeadCellProps: {
              align: "center",
              sx: {
                boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.1)",
                backgroundColor: theme.palette.background.paper,
              },
            },
            muiTableBodyCellProps: ({row}: any) => ({
              align: "center",
              sx: {
                boxShadow: "4px 0 8px 0 rgba(0, 0, 0, 0.1)",
                backgroundColor: checkBackgroundAndTextColor(row).background,
              },
            }),
          },
        }}
        renderRowActions={({row}) => (
          <>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{position: "relative", height: "100%"}}
            >
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: getClassificationBorderColor(row)?.primary,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  p: `${pxToRem(10)} 0`,
                  width: pxToRem(35),
                }}
              >
                <Icon
                  sx={{
                    zIndex: 2,
                    color: "white",
                  }}
                >
                  {getClassificationBorderColor(row)?.icon}
                </Icon>
              </Box>
              {props.actionButtonsData?.map((buttonData: ButtonData<T>) => {
                return (
                  <Link
                    key={buttonData.tooltipText}
                    to={
                      buttonData?.redirection
                        ? buttonData?.redirection(row.original)
                        : "#"
                    }
                  >
                    <CustomIconButton
                      tooltipText={buttonData.tooltipText}
                      iconName={buttonData.iconName}
                      onClick={(e) => {
                        buttonData?.onClick &&
                          buttonData?.onClick(row.original, e);
                      }}
                      disabled={buttonData?.disabled}
                      sx={{
                        color: checkBackgroundAndTextColor(row).text,
                        filter:
                          row.original.refNext ===
                            "074/22_WSBA223062 GABTA.100" &&
                          buttonData.completed
                            ? "opacity(50%)"
                            : undefined,
                      }}
                    />
                  </Link>
                );
              })}

              <Link to={handleShowProcess(row)}>
                <CustomIconButton
                  tooltipText="Ver/Alterar detalhes do processo"
                  iconName="feed"
                  sx={{
                    color: checkBackgroundAndTextColor(row).text,
                  }}
                />
              </Link>
            </Stack>
          </>
        )}
        muiTableBodyRowProps={({row}) => ({
          sx: {
            hover: false,
            backgroundColor: checkBackgroundAndTextColor(row).background,
            "&:hover td": {
              backgroundColor: checkBackgroundAndTextColor(row).background,
              opacity: 0.8,
            },
          },
        })}
        muiTableContainerProps={{
          sx: (theme) => ({
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
          }),
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            display: "flex",
            flexDirection: "column",
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            fontSize: pxToRem(13),
            fontWeight: 600,

            ".Mui-TableHeadCell-Content-Wrapper": {
              whiteSpace: "nowrap",
            },

            "& .Mui-TableHeadCell-content": {
              alignItems: "center",
            },
          },
        }}
        muiTableBodyCellProps={({row}) => ({
          sx: {
            fontSize: pxToRem(12),
            color: checkBackgroundAndTextColor(row).text,
            backgroundColor: checkBackgroundAndTextColor(row).background,
          },
        })}
        localization={MRT_Localization_PT_BR}
        muiBottomToolbarProps={{
          sx: {backgroundColor: theme.palette.background.paper},
        }}
        muiTopToolbarProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
          },
        }}
        muiToolbarAlertBannerProps={{
          sx: {
            fontSize: ".8rem",
            backgroundColor: theme.palette.background.paper,
          },
        }}
        muiColumnActionsButtonProps={{size: "small"}}
        renderToolbarInternalActions={({table}) => (
          <>
            {props.renderNewToolbarAction}
            <ToolbarButton
              tooltipText="Salvar configuração de visualização atual"
              iconName="save"
              onClick={handleSaveTableView}
            />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleGlobalFilterButton table={table} />
            {/* <MRT_ToggleFiltersButton table={table} /> */}
            {props.enableCsvDownload && <CsvTableDownloader table={table} />}
          </>
        )}
      />
    </Box>
  );
};

export default ActivityTable;
