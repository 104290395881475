export interface AutoCompleteOption {
  id: string;
  label: string;
}

export enum CloneItem {
  "content_copy" = "content_copy",
  "content_paste" = "content_paste",
  "close" = "close",
}
export interface CloneCargoItemsOptions {
  icon: CloneItem;
}
