/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Typography,
  useTheme,
  Drawer,
  Button,
  Icon,
  Tooltip,
} from "@mui/material";
import {Grid} from "@mui/system";
import {
  Task,
  MonitoredField,
  AvailableUiComponents,
} from "components/ModalCompleteActivity/CompleteActivity.types";
import pxToRem from "hooks/usePxToRem";
import {Dispatch, Fragment, SetStateAction, useEffect, useState} from "react";
import {CustomInputWrapper, CustomUploader, CustomInput} from "ui";
import {AutocompleteField} from "./components";
import {GetProcessFiles} from "services/api_v2/Processes/Processes.service";
import ProcessSectionViewHandleData from "../ProcessSectionView/ProcessSectionViewHandleData";
import {CorrespondenceModal, HelperIcon} from "components/HelperIconAndModal";
import useCopyToClipboard from "hooks/useCopyToClipboard";

type Props = {
  taskData: Task;
  handleBoxScroll: () => void;
  setTaskData: Dispatch<SetStateAction<Task>>;
};

const FieldsToFill = (props: Props) => {
  const theme = useTheme();
  const {taskData, handleBoxScroll, setTaskData} = props;

  const fileFields =
    taskData?.monitoredFields?.filter((item) => !!item?.groupFile) ?? [];

  const handleChangeFields = (
    e: React.ChangeEvent<HTMLInputElement> | null,
    field: MonitoredField,
    newValue?: any,
  ) => {
    if (taskData?.monitoredFields) {
      const changingItemIndex =
        taskData.monitoredFields?.findIndex((item) => item.id === field.id) ??
        0;

      const oldMonitoredFieldsArray = taskData.monitoredFields;
      oldMonitoredFieldsArray[changingItemIndex] = {
        ...field,
        value: newValue ?? {id: null, label: e?.target?.value ?? ""},
        id: field?.id,
      };
      setTaskData((prev) => ({
        ...prev,
        monitoredFields: oldMonitoredFieldsArray,
      }));
    }
  };

  const handleChangeFiles = (
    field: MonitoredField,
    acceptedFiles?: any,
    group?: any,
    groupName?: string,
  ) => {
    const isAddingFile = !!group;

    if (taskData.monitoredFields) {
      const changingItemIndex =
        taskData.monitoredFields?.findIndex(
          (item) => item.groupFile?.id === field.groupFile?.id,
        ) ?? 0;

      const oldMonitoredFieldsArray = taskData.monitoredFields;

      oldMonitoredFieldsArray[changingItemIndex] = {
        ...field,
        value: {
          id: null,
          label: JSON.stringify({idGroupFile: field?.groupFile?.id}),
        },
        id: field?.id ?? 0,
      };

      if (isAddingFile) {
        setTaskData((prev) => ({
          ...prev,
          files: [
            ...prev.files,
            ...acceptedFiles.map((file) => ({
              file: file,
              idGroupFile: +group,
              groupFileName: groupName,
            })),
          ],
          monitoredFields: oldMonitoredFieldsArray,
        }));
      } else {
        const filesList = taskData.files;

        filesList.splice(changingItemIndex, 1);
        setTaskData((prev) => ({
          ...prev,
          files: filesList,
          monitoredFields: oldMonitoredFieldsArray,
        }));
      }
    }
  };

  const getNameOfFieldLabel = (field: MonitoredField) => {
    if (field.label === "Arquivo") {
      return field?.groupFile?.name ?? "";
    } else {
      return field.label ?? "";
    }
  };

  const completeActivityMessage = (
    variant: "primary" | "secondary" = "primary",
  ) => {
    return (
      <Typography
        fontSize={variant === "primary" ? pxToRem(16) : pxToRem(14)}
        fontStyle="italic"
        p={pxToRem(10)}
      >
        {`Para concluir esta atividade basta clicar em 'Concluir Atividade' e pronto. Estamos tornando isso o mais fácil possível para você continuar com suas tarefas de forma rápida e eficiente. `}
      </Typography>
    );
  };

  const [fileNameStartsAt, setFileNameStartsAt] = useState(0);

  const checkNumberOfUploadingDocument = async () => {
    try {
      const idProcess = taskData?.process?.id;
      const idGroupFile = taskData?.monitoredFields?.[0]?.groupFile?.id;

      if (!idProcess || !idGroupFile) {
        return;
      }

      const response = await GetProcessFiles(idProcess, idGroupFile);
      const numberOfGroupFiles = response?.data?.meta?.total;

      setFileNameStartsAt(numberOfGroupFiles);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    checkNumberOfUploadingDocument();
  }, []);

  /**
   * novo abaixo
   */

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [componentToRender, setComponentToRender] =
    useState<AvailableUiComponents>();

  const handleOpenDrawer = (uiComponent: AvailableUiComponents) => {
    setComponentToRender(uiComponent);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = (
    uiComponent: AvailableUiComponents | undefined,
  ) => {
    setDrawerOpen(false);
    setComponentToRender(undefined);

    const oldTaskData: typeof taskData = structuredClone(taskData);
    oldTaskData.monitoredFields?.forEach((element, index) => {
      if (
        element.tableName === "ui_component" &&
        element.columnName === uiComponent
      ) {
        if (oldTaskData?.monitoredFields) {
          oldTaskData.monitoredFields[index] = {
            ...element,
            uiComponentChecked: true,
          };
        }
      }
    });
    setTaskData(oldTaskData);
  };

  const isALegalPersonField = (field) => {
    return (
      field?.label === "Notify" ||
      field?.label === "Shipper" ||
      field?.label === "Consignee" ||
      field?.label === "Agente de origem"
    );
  };

  const [openCorrespondenceModal, setOpenCorrespondenceModal] = useState(false);
  const [correspondenceModalTitle, setCorrespondenceModalTitle] = useState("");
  const [correspondenceModalContent, setCorrespondenceModalContent] =
    useState("");

  const handleOpenHelperModal = (content: string, title: string) => {
    setCorrespondenceModalTitle(title);
    setCorrespondenceModalContent(content);
    setOpenCorrespondenceModal(true);
  };

  const handleCloseHelperModal = () => {
    setOpenCorrespondenceModal(false);
    setCorrespondenceModalContent("");
  };
  const {copyToClipboard} = useCopyToClipboard();

  const getLabelHelper = (field: MonitoredField) => {
    if (isALegalPersonField(field)) {
      return (
        <HelperIcon
          onClick={() =>
            handleOpenHelperModal(
              field?.fieldValue?.correspondence ?? "",
              getNameOfFieldLabel(field),
            )
          }
        />
      );
    }

    if (field.isDisabled) {
      return (
        <Tooltip title={"Copiar valor"} arrow placement="top">
          <Icon
            data-testid={"copy-icon"}
            sx={{
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() =>
              copyToClipboard(
                field?.fieldValue?.value ?? "",
                `${getNameOfFieldLabel(field)} copiado(a).`,
              )
            }
          >
            content_copy
          </Icon>
        </Tooltip>
      );
    }
  };

  return (
    <Fragment>
      <CorrespondenceModal
        correspondenceText={correspondenceModalContent}
        isOpen={openCorrespondenceModal}
        onClose={handleCloseHelperModal}
        correspondenceTitle={correspondenceModalTitle}
      />
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: ` ${pxToRem(4)} 0 0 ${pxToRem(4)}`,
          },
        }}
      >
        <Grid sx={{width: "95vw"}}>
          <ProcessSectionViewHandleData
            processId={+taskData.process?.id}
            componentToRender={componentToRender}
            handleCloseDrawer={handleCloseDrawer}
          />
        </Grid>
      </Drawer>

      {!taskData?.monitoredFields &&
        !taskData.isMultiplePath &&
        completeActivityMessage()}

      {taskData?.monitoredFields?.map((field, index) => (
        <CustomInputWrapper
          title={getNameOfFieldLabel(field)}
          key={(field?.id ?? index) * index}
          xs={12}
          required
          helper={getLabelHelper(field)}
        >
          {field?.isReadOnly && field.tableName !== "ui_component" && (
            <Box
              onScroll={handleBoxScroll}
              sx={{
                overflowY: "scroll",
                maxHeight: pxToRem(200),
                [theme.breakpoints.up("md")]: {maxHeight: pxToRem(300)},
                mb: pxToRem(15),
              }}
            >
              {field?.fieldValue?.value ? (
                <Typography
                  key={field.id}
                  component="pre"
                  whiteSpace="pre-wrap"
                  sx={{
                    fontStyle: field?.fieldValue?.value ? "normal" : "italic",
                  }}
                >
                  {field?.fieldValue?.value}
                </Typography>
              ) : (
                <>
                  <Typography
                    fontStyle={"italic"}
                    fontWeight={400}
                    p={`${pxToRem(10)} ${pxToRem(10)} 0 ${pxToRem(10)}`}
                  >
                    Não há informações para exibir.
                  </Typography>
                  {completeActivityMessage("secondary")}
                </>
              )}
            </Box>
          )}

          {field?.isReadOnly && field.tableName === "ui_component" && (
            <Box>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                onClick={() =>
                  handleOpenDrawer(field.columnName as AvailableUiComponents)
                }
                sx={{display: "flex", justifyContent: "space-between"}}
              >
                {`Conferir ${field.label}`}
                <Box sx={{display: "flex", alignContent: "center"}}>
                  {field.uiComponentChecked ? (
                    <Icon color="success" sx={{ml: pxToRem(10)}}>
                      check
                    </Icon>
                  ) : (
                    <Icon color="error" sx={{ml: pxToRem(10)}}>
                      close
                    </Icon>
                  )}

                  <Icon sx={{ml: pxToRem(10)}}>arrow_forward_ios</Icon>
                </Box>
              </Button>
            </Box>
          )}

          {!field.isReadOnly &&
            field.columnType === "number" &&
            field.tableName === "files" && (
              <>
                <CustomUploader
                  singleFile
                  group={field?.groupFile?.id.toString()}
                  fileNameWithTextOverflowElipsis
                  handleSetDocuments={(acceptedFiles?: any, group?: any) =>
                    handleChangeFiles(
                      field,
                      acceptedFiles,
                      group,
                      field.groupFile?.name,
                    )
                  }
                  customFileNameStartsAt={fileNameStartsAt}
                  customFileNamePrefix={field.groupFile?.name
                    .toUpperCase()
                    .trim()
                    .replace(" ", "_")}
                />
                {fileFields?.length > 1 &&
                  fileFields[0].groupFile?.id === field.groupFile?.id && (
                    <Divider
                      sx={{
                        width: "100%",
                        margin: `${pxToRem(20)} 0`,
                      }}
                    />
                  )}
              </>
            )}

          {!field.isReadOnly && field.columnType === "DateTime" && (
            <CustomInput
              type="date"
              size="small"
              name="dateField"
              value={field.value?.label as string}
              onChange={(e) => handleChangeFields(e, field)}
              required
              disabled={field?.isDisabled}
            />
          )}
          {!field.isReadOnly &&
            field.columnType === "number" &&
            field.tableName !== "files" && (
              <CustomInput
                type="number"
                placeholder={field.label ?? ""}
                size="small"
                name="numberField"
                required
                onChange={(e) => handleChangeFields(e, field)}
                value={field.value?.label as string}
                disabled={field?.isDisabled}
              />
            )}
          {!field?.isReadOnly && field?.columnType === "select" && (
            <AutocompleteField
              field={field}
              handleChangeFields={handleChangeFields}
              disabled={field?.isDisabled}
            />
          )}
          {!field.isReadOnly &&
            field.columnType === "string" &&
            field.columnName !== "email_to" && (
              <CustomInput
                type="text"
                size="small"
                required
                placeholder={field.label ?? ""}
                name="stringField"
                onChange={(e) => handleChangeFields(e, field)}
                value={field.value?.label as string}
                disabled={field?.isDisabled}
              />
            )}
        </CustomInputWrapper>
      ))}
    </Fragment>
  );
};

export default FieldsToFill;
