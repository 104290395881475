const useMasks = () => {
  const cnpjMask = (cnpj: string) => {
    const masked = `${cnpj[0]}${cnpj[1]}.${cnpj[2]}${cnpj[3]}${cnpj[4]}.${cnpj[5]}${cnpj[6]}${cnpj[7]}/${cnpj[8]}${cnpj[9]}${cnpj[10]}${cnpj[11]}-${cnpj[12]}${cnpj[13]}`;
    return masked;
  };

  const cpfMask = (cpf: string) => {
    const masked = `${cpf[0]}${cpf[1]}${cpf[2]}.${cpf[3]}${cpf[4]}${cpf[5]}.${cpf[6]}${cpf[7]}${cpf[8]}-${cpf[9]}${cpf[10]}`;
    return masked;
  };

  return {cnpjMask, cpfMask};
};

export default useMasks;
