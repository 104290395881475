import {useMemo} from "react";
import {TaxesData} from "./ActivityTaxesListing.types";

import {MRT_ColumnDef} from "material-react-table";
import {CustomSelect} from "ui";
import {Typography} from "@mui/material";
import pxToRem from "hooks/usePxToRem";
import {Controller, useFormContext} from "react-hook-form";

const ActivityTaxesListingData = () => {
  const {watch, control} = useFormContext();

  const houseKnowledges = [
    {name: "Nenhum", id: null},
    ...watch("knowledges")
      .filter((item) => item.typeKnowledge === "House" && item.isActive)
      .map((item) => ({name: item.number, id: item.id.toString()})),
  ];

  const paymentColorProp = {
    muiTableBodyCellProps: {
      sx: (theme) => ({
        backgroundColor: theme.palette.info[100],
        color: theme.palette.grey[700],
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      }),
    },
  };

  const receiptColorProp = {
    muiTableBodyCellProps: {
      sx: (theme) => ({
        backgroundColor: theme.palette.success[100],
        color: theme.palette.grey[700],
        borderRight: `1px solid ${theme.palette.grey[300]}`,
      }),
    },
  };

  const columnsData = useMemo<MRT_ColumnDef<TaxesData>[]>(
    () => [
      {
        id: "generalTaxes",
        header: "Geral",
        columns: [
          {
            accessorKey: "idKnowledgeHouse",
            header: "Conhecimento",
            enableHiding: false,
            Cell: ({row}) => (
              <Controller
                name={`processCharges[${row.index}].idKnowledgeHouse`}
                control={control}
                render={({field: {onChange, value}}) => (
                  <CustomSelect
                    content={houseKnowledges}
                    size="small"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    textOnEmptySelect={
                      <Typography sx={{fontSize: pxToRem(12), opacity: 0.5}}>
                        Selecione o House
                      </Typography>
                    }
                    sx={{
                      padding: "0",
                      border: "none",
                      outline: "none",
                      "& fieldset": {
                        border: "none",
                      },
                      "&:focus": {
                        outline: "none",
                      },
                    }}
                  />
                )}
              />
            ),
          },
          {
            accessorKey: "rate",
            header: "Taxa",
            enableHiding: false,
          },

          {
            accessorKey: "equipment",
            header: "Equipamento",
          },
          {
            accessorKey: "displayCharge",
            header: "Taxa",
          },
          {
            accessorKey: "originCharge",
            header: "Origem taxa",
          },
          {
            accessorKey: "dateExpiry",
            header: "Validade",
          },
          {
            accessorKey: "typeService",
            header: "Tipo serviço",
          },
        ],
      },

      //novo
      {
        id: "pgto",
        header: "Pagamento",

        columns: [
          {
            accessorKey: "modalityPayment",
            header: "Tipo",
            ...paymentColorProp,
          },
          {
            accessorKey: "payTo",
            header: "Pagar para",
            ...paymentColorProp,
          },
          {
            accessorKey: "paymentPerson.name",
            id: "paymentPersonName",
            header: "Pagador",
            ...paymentColorProp,
          },
          {
            accessorKey: "paymentCurrency",
            header: "Moeda Pagamento",
            ...paymentColorProp,
          },
          {
            accessorKey: "typePayment",
            header: "Tipo pagamento",
            ...paymentColorProp,
          },
          {
            accessorKey: "billingPayment",
            header: "tipo recebimento",
            ...paymentColorProp,
          },
          {
            accessorKey: "quantityPayment",
            header: "Quant. pagamento",
            ...paymentColorProp,
          },
          {
            accessorKey: "valuePaymentUnit",
            header: "Valor pagamento",
            ...paymentColorProp,
          },
          {
            accessorKey: "valuePaymentMinimum",
            header: "Valor pgto. min.",
            ...paymentColorProp,
          },
          {
            accessorKey: "valuePaymentTotal",
            header: "Valor pagamento total",
            ...paymentColorProp,
          },
        ],
      },
      {
        id: "receipt",
        header: "Recebimento",
        columns: [
          {
            accessorKey: "valueReceiptUnit",
            header: "Valor recebimento",
            ...receiptColorProp,
          },
          {
            accessorKey: "valueReceiptMinimum",
            header: "Valor Recebimento Mín.",
            ...receiptColorProp,
          },
          {
            accessorKey: "valueReceiptTotal",
            header: "Valor recebimento total",
            ...receiptColorProp,
          },
          {
            accessorKey: "receiptPerson.name",
            id: "receiptPersonName",
            header: "Pessoa recebimetno",
            ...receiptColorProp,
          },
          {
            accessorKey: "typeReceipt",
            header: "Tipo recebimento",
            ...receiptColorProp,
          },

          {
            accessorKey: "receiveFrom",
            header: "Receber de",
            ...receiptColorProp,
          },
          {
            accessorKey: "quantityReceipt",
            header: "Quant. recebimento",
            ...receiptColorProp,
          },
          {
            accessorKey: "modalityReceipt",
            header: "Modalidade recebimento",
            ...receiptColorProp,
          },
        ],
      },
    ],
    [],
  );

  const ratesData: TaxesData[] = [
    {
      id: "1",
      rate: "Frete Marítimo",
      equipment: "40 High Cube",
      paymentCurrency: "USD",
      totalPaymentAmount: "10.000,00",
      totalReceiptAmount: "10.000",
      receiptCurrency: "USD",
    },
    {
      id: "2",
      rate: "ANS",
      equipment: "",
      paymentCurrency: "BRL",
      totalPaymentAmount: "10.000,00",
      totalReceiptAmount: "10.000",
      receiptCurrency: "BRL",
    },
    {
      id: "3",
      rate: "Capatazia",
      equipment: "40 High Cube",
      paymentCurrency: "BRL",
      totalPaymentAmount: "1.659,00",
      totalReceiptAmount: "1.659,00",
      receiptCurrency: "BRL",
    },
    {
      id: "4",
      rate: "BL Fee",
      equipment: "",
      paymentCurrency: "BRL",
      totalPaymentAmount: "563,00",
      totalReceiptAmount: "563,00",
      receiptCurrency: "BRL",
    },
  ];

  return {columnsData, ratesData};
};

export default ActivityTaxesListingData;
