import {useState} from "react";
import pxToRem from "hooks/usePxToRem";

import {Stack, Typography} from "@mui/material";

import {CustomButton} from "ui";
import {ConfirmModal, DefaultCardStructure} from "components";
import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {
  DeleteLogisticBillLadingLoad,
  PatchProcessById,
} from "services/api_v2/Processes/Processes.service";
import useAlertMessage from "hooks/useAlertMessage";
import useToast from "hooks/useToast";
import {Item} from "./components";
import {calcConsideredWeight} from "../../utils/calculateConsideredWeight";
import {useParams} from "react-router";
import {CloneCargoItemsOptions, CloneItem} from "types/ComponentsTypes";

type Props = {
  loadIndex: number;
};

const CargoItems = (props: Props) => {
  const {loadIndex} = props;
  const {getValues, watch, reset, formState, setValue} = useFormContext();
  const [showToast] = useToast();
  const [showAlert] = useAlertMessage();
  const {id: processId} = useParams<{id: string}>();

  const initialCargoItem = {
    number: "",
    seal: "",
    equipment: null,
    quantity: 0,
    netWeight: 0,
    grossWeight: 0,
    measurement: 0,
    tare: 0,
    typeItemLoad: "",
    barSeal: "",
    typePackages: null,
    packageContainer: null,
    dangerousGoodsClass: "",
    dangerousGoodsCode: "",
  };

  const cancelledProcess = watch("agencyingSituation") === "Cancelado";
  const houseId = useWatch({name: "houseId"});
  const equipments = useWatch({name: "processEquipments"});

  const {fields, append, remove, update} = useFieldArray({
    name: "logisticBillLadingLoads",
    keyName: "idForm",
  });

  const totalOfEquipments = () => {
    const total = equipments.reduce((acc, curr) => acc + curr?.quantity, 0);
    return total;
  };

  const handleAppendNewItem = () => {
    if (getValues("logisticBillLadingLoads").length === totalOfEquipments()) {
      showToast(
        "O número de equipamentos é igual ou maior ao número de itens",
        "warning",
      );
    } else {
      append(initialCargoItem);
    }
  };

  const setHouseMeasurements = (shouldDirtyFields = true) => {
    const cargoItems = getValues("logisticBillLadingLoads");
    const totalGrossWeight = cargoItems.reduce(
      (acc, prev) => acc + prev.grossWeight,
      0,
    );
    setValue(`processLoads[${loadIndex}].grossWeight`, totalGrossWeight, {
      shouldDirty: shouldDirtyFields,
    });
    const totalMeasurements = cargoItems.reduce(
      (acc, prev) => acc + prev.measurement,
      0,
    );
    setValue(`processLoads[${loadIndex}].cubicMeters`, totalMeasurements, {
      shouldDirty: shouldDirtyFields,
    });

    const totalQuantities = cargoItems.reduce(
      (acc, prev) => acc + prev.quantity,
      0,
    );

    setValue(`processLoads[${loadIndex}].quantities`, totalQuantities, {
      shouldDirty: shouldDirtyFields,
    });

    const loadData = calcConsideredWeight(
      totalMeasurements ?? 0,
      totalGrossWeight ?? 0,
    );
    setValue(
      `processLoads[${loadIndex}].consideredWeight`,
      loadData.consideredWeight,
      {shouldDirty: shouldDirtyFields},
    );
    setValue(`processLoads[${loadIndex}].cubeWeight`, loadData.cubeWeight, {
      shouldDirty: shouldDirtyFields,
    });
  };

  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    itemId?: number;
    index?: number;
  }>({});

  /**
   * Não vamos mais deletar itens de carga, uma vez que já trazemos a quantidade
   * correta conforme quantidade de equipamentos. O botão de apagar foi substituído
   * por um botão para limpar os campos.
   */

  const handleDeleteLogisticBillLading = async (field: any, index: number) => {
    if (!field.id) {
      remove(index);
      return;
    }

    setItemToDelete({itemId: field.id as number, index: index});
    setConfirmModalIsOpen(true);
  };

  const handleClose = () => {
    setConfirmModalIsOpen(false);
  };
  const confirmDeletionPackage = async () => {
    if (!itemToDelete?.itemId || itemToDelete?.index === undefined) {
      return;
    }
    try {
      await DeleteLogisticBillLadingLoad(itemToDelete.itemId);
      remove(itemToDelete.index);

      const newBillLadingLoads = getValues("logisticBillLadingLoads").filter(
        (item) => item !== itemToDelete.itemId,
      );

      reset({
        ...formState.defaultValues,
        logisticBillLadingLoads: newBillLadingLoads,
      });

      handleClose();
      setHouseMeasurements(false);

      const loadValues = getValues("processLoads");
      const newLoads = {
        logisticLoads: [
          {
            id: loadValues[0]?.id,
            quantities: loadValues[0]?.quantities,
            grossWeight: loadValues[0]?.grossWeight,
            cubicMeters: loadValues[0]?.cubicMeters,
            consideredWeight: loadValues[0]?.consideredWeight,
            cubeWeight: loadValues[0]?.cubeWeight,
          },
        ],
      };

      await PatchProcessById(+processId, newLoads);

      showToast("Item removido com sucesso", "success");
    } catch (e: any) {
      console.error(e);
      showAlert(e?.response?.data?.message, "error");
    }
  };

  const getDefaultItemValues = (): CloneCargoItemsOptions[] => {
    const defaultItemValues = fields.map(() => ({
      icon: CloneItem.content_copy,
    }));
    return defaultItemValues;
  };

  const [cloningItemValues, setCloningItemValues] = useState<any>(null);

  const [cloneItemConfig, setCloneItemConfig] = useState<
    CloneCargoItemsOptions[]
  >(getDefaultItemValues());

  const handleClickOnCloneItem = (index: number, valuesToClone: any) => {
    const cloneItems = structuredClone(cloneItemConfig);

    if (cloneItems[index].icon === CloneItem.close) {
      setCloningItemValues(null);
      cloneItems.forEach((item) => (item.icon = CloneItem.content_copy));
      setCloneItemConfig(cloneItems);
      showToast("Clonagem encerrada!");
      setIsCloningItem(false);
      return;
    }

    if (cloneItems[index].icon === CloneItem.content_paste) {
      const dataToClone = structuredClone(cloningItemValues);

      delete dataToClone?.seal;
      delete dataToClone?.barSeal;
      delete dataToClone?.id;
      delete dataToClone?.number;

      update(index, {
        id: getValues("logisticBillLadingLoads")[index]?.id,
        ...dataToClone,
      });
      setHouseMeasurements();
      showToast("Dados clonados!", "warning");

      return;
    }

    if (cloneItems[index].icon === CloneItem.content_copy) {
      setCloningItemValues(valuesToClone);
      cloneItems.forEach((item, forIndex) => {
        return forIndex === index
          ? (item.icon = CloneItem.close)
          : (item.icon = CloneItem.content_paste);
      });
      showToast("Item copiado!", "success");

      setCloneItemConfig(cloneItems);

      return;
    }
  };

  const [isCloningItem, setIsCloningItem] = useState(false);

  return (
    <>
      {confirmModalIsOpen && (
        <ConfirmModal
          isOpen={confirmModalIsOpen}
          title={"Deletar item de carga"}
          subtitle={`Esta ação não pode ser revertida!`}
          confirmButtonFn={() => confirmDeletionPackage()}
          onClose={handleClose}
        />
      )}
      <DefaultCardStructure
        title="Itens Da Carga - FCL"
        hasDivider={true}
        isSubcard={false}
        button={
          <>
            <CustomButton
              onClickFn={() => {
                if (isCloningItem) {
                  setCloningItemValues(null);
                  setCloneItemConfig(getDefaultItemValues());
                }
                setIsCloningItem((prev) => !prev);
              }}
              variant="text"
            >
              {isCloningItem ? `Finalizar clonagem` : `Clonar Item`}
            </CustomButton>
            <CustomButton
              startIcon="add"
              onClickFn={handleAppendNewItem}
              disabled={
                !houseId ||
                getValues("processEquipments").length === 0 ||
                cancelledProcess
              }
            >
              Incluir item
            </CustomButton>
          </>
        }
      >
        <Stack sx={{gap: pxToRem(8)}}>
          {fields.length === 0 && (
            <Typography>Não existem itens de carga no processo.</Typography>
          )}
          {fields.map((field: any, index) => (
            <Item
              key={index}
              field={field}
              index={index}
              loadIndex={props.loadIndex}
              handleDeleteLogisticBillLading={handleDeleteLogisticBillLading}
              setHouseMeasurements={setHouseMeasurements}
              cloneItemConfig={cloneItemConfig[index]}
              handleClickOnCloneItem={handleClickOnCloneItem}
              isCloningItem={isCloningItem}
            />
          ))}
        </Stack>
      </DefaultCardStructure>
    </>
  );
};

export default CargoItems;
