const formatNameToInitialLetters = (name: string) => {
  const [firstName, lastName] = name.split(" ");
  const initialLettersOfUsername = [
    firstName?.charAt(0),
    lastName?.charAt(0),
  ].join("");

  return initialLettersOfUsername;
};

export default formatNameToInitialLetters;
